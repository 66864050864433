import { Link as GatsbyLink, graphql } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import SEO from '@connected/SEO';
import Logo from '@icons/Logo';
import { ButtonVariant } from '@presentation/Button/Button.types';
import Link from '@presentation/Link';
import formatTitle from '@utils/formatTitle';
const NotFoundPage = ({ data }) => (<>
    <SEO title="404: Not found" url="/404"/>
    <section className="template-banner template-banner--404 greyscale">
      <div className="template-banner__bg">
        <Image fluid={data.file.childImageSharp.fluid}/>
      </div>
      <div className="site-wide">
        <div className="grid-column">
          <GatsbyLink className="logo" to="/">
            <Logo />
          </GatsbyLink>
          <h1>{formatTitle('404 page not found')}</h1>
          <p>Sorry but the page you were looking for can’t be found.</p>
          <Link text="Learn more about Kentico" to="/" variant={ButtonVariant.OrangeSolid}/>
        </div>
      </div>
    </section>
  </>);
export default NotFoundPage;
export const query = graphql `
  query NotFoundQuery {
    file(name: { eq: "bg-default" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
